const navMenuItems = userData => [
  {
    title: 'Home',
    icon: 'HomeIcon',
    route: 'home',
  },
  ...(userData.is_superuser ? [
    {
      title: 'Users',
      route: 'users',
      icon: 'UsersIcon',
    },
  ] : []),
]

export default navMenuItems
