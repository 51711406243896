<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  computed: {
    navItems() {
      const userData = this.$store.state.app.userData ?? JSON.parse(localStorage.userData)
      return navMenuItems(userData)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
